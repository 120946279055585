<template>
  <v-container>
    <v-row class="header-account">
      <v-col cols="4" class="d-flex align-center" v-if="account.logo">
        <v-img
          :src="account.logo"
          max-height="48px"
          max-width="216px"
          class="ml-7"
        ></v-img>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-center pl-6"
        :class="account.logo ? 'justify-end' : ''"
      >
        <div>
          <label>{{ $t('report.account') }}</label>
          <h3>
            <p>
              {{ account.name }}
            </p>
          </h3>
        </div>
      </v-col>
      <v-col
        cols="4"
        class="d-flex align-center"
        :offset="!account.logo ? 4 : 0"
      >
        <v-img
          aspect-ratio="1.4"
          contain
          max-height="40px"
          src="img/logos/logo-digifarmz.svg"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportHeaderAccount',

  props: {
    account: { type: Object, required: true },
  },
}
</script>

<style scoped>
.v-image__image--contain {
  background-position: left center !important;
}
.header-account label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}
.header-account p {
  font-size: 22px;
}
@media print {
  .header-account p {
    font-family: 'Rubik';
    font-size: 17px;
  }
}
</style>
